(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/draggable-container/assets/javascripts/draggable-container.js') >= 0) return;  svs.modules.push('/components/lb-ui/draggable-container/assets/javascripts/draggable-container.js');
"use strict";


const {
  useEffect,
  useRef,
  useState
} = React;
const {
  clx
} = svs.components.lbUtils;
const SCROLL_MULTIPLIER = 2;
const DraggableContainer = _ref => {
  let {
    children,
    className,
    indexTab,
    preventClickPropagation = true
  } = _ref;
  const containerRef = useRef(null);
  const childrenRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [isScrollable, setIsScrollable] = useState(false);
  useEffect(() => {
    const checkScrollable = () => {
      if (containerRef.current && childrenRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const childrenWidth = childrenRef.current.scrollWidth;
        setIsScrollable(childrenWidth > containerWidth);
      }
    };
    checkScrollable();
    window.addEventListener('resize', checkScrollable);
    return () => {
      window.removeEventListener('resize', checkScrollable);
    };
  }, []);
  const handleClick = e => {
    if (preventClickPropagation) {
      e.preventDefault();
      e.stopPropagation();
    }
  };
  const startDrag = e => {
    e.preventDefault();
    setIsDragging(true);
    setStartX(e.pageX - e.currentTarget.offsetLeft);
    setScrollLeft(e.currentTarget.scrollLeft);
  };
  const stopDrag = () => setIsDragging(false);
  const onDrag = e => {
    if (!isDragging) {
      return;
    }
    e.preventDefault();
    const x = e.pageX - e.currentTarget.offsetLeft;
    const newPositionX = (x - startX) * SCROLL_MULTIPLIER;
    e.currentTarget.scrollLeft = scrollLeft - newPositionX;
  };
  const validatedIndexTab = typeof indexTab === 'number' && !isNaN(indexTab) ? indexTab : 0;
  return React.createElement("div", {
    className: clx('pg_draggable_container', className),
    onClick: handleClick,
    onMouseDown: startDrag,
    onMouseLeave: stopDrag,
    onMouseMove: onDrag,
    onMouseUp: stopDrag,
    ref: containerRef,
    style: {
      cursor: isDragging ? 'grabbing' : 'grab'
    },
    tabIndex: isScrollable ? validatedIndexTab : -1
  }, React.createElement("div", {
    className: "pg_draggable_container__content",
    ref: childrenRef
  }, children));
};
setGlobal('svs.components.lbUi.DraggableContainer', DraggableContainer);

 })(window);